import React, {
  createContext,
  ReactElement,
  ReactNode,
  useState,
  useEffect,
} from "react";
import Helmet from "react-helmet";

export type Theme = "light" | "dark";

export type ThemeData = {
  theme: Theme | null;
  toggleTheme: () => void;
};

export const ThemeContext = createContext<ThemeData>({
  theme: null,
  toggleTheme: (): void => undefined,
});

type Props = {
  children: ReactNode;
};

export function ThemeProvider(props: Props): ReactElement<Props> {
  const { children } = props;
  const [theme, setTheme] = useState<Theme | null>(null);

  useEffect(() => {
    setTheme(window.__theme);
    window.__onThemeChange = (): void => {
      setTheme(window.__theme);
    };
  }, []);

  function toggleTheme(): void {
    window.__setPreferredTheme(theme === "dark" ? "light" : "dark");
  }

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
      }}
    >
      <Helmet>
        <meta
          name="theme-color"
          content={theme === "dark" ? "#1f232d" : "#fafcfc"}
        />
      </Helmet>
      {children}
    </ThemeContext.Provider>
  );
}
